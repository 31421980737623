import axios from "axios";
import { Notification } from 'element-ui'

let base_url = process.env.VUE_APP_GLOB_API_URL;
// let url_white_list = init.url_white_list;


let newReq = axios.create({
  baseURL: base_url,
  timeout: 30 * 1000
});

let req = newReq

// 上传
req.upload = ( url , formData) => {
  return new Promise((resolve, reject) => {
    newReq({
      url: url,
      method: "post",
      data: formData, //上传文件内容
      cache: false, //不必须不从缓存中读取
      processData: false,//必须处理数据 上传文件的时候，则不需要把其转换为字符串，因此要改成false
      contentType: false,//必须 发送数据的格式
    }).then((res) => {resolve(res)})
      .catch((err)=> {reject(err)})
  })
}

// 添加请求拦截器
newReq.interceptors.request.use(function (config) {
  if (config.url.indexOf("http") != -1) {
    config.baseURL = "";
  } else {
    if (!base_url) {
      Notification({
        title: '请配置config文件base_url',
        type: 'error'
      });
      return;
    }
    if (localStorage.getItem("token")) {
      // if (url_white_list != null) {
      //   if (url_white_list.indexOf(config.url) == -1) {
      //     config.headers["Authorization"] = localStorage.getItem("token");
      //   }
      // } else {
      //   config.headers["Authorization"] = localStorage.getItem("token");
      // }
    }
  }
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  return Promise.reject(error);
});
// 添加响应拦截器
newReq.interceptors.response.use(function (response) {
  if (response.config.baseURL == "") {
    return response.data;
  }
  const { code, msg } = response.data
  if (code == 200) {
    return response.data
  } else {
    Notification({
      title: '提示',
      type: 'error',
      message: msg
    });
    return Promise.reject(msg)
  }
})

export default req;
