import req from "./request";
import './index.scss'
import loaded from "./image/loaded.png";

export default {
  name: "uploadImg",
  props: {
    action: {type: String, required: true},
    imgLength: {type: Number, default: 1},
    uploadResponse: {type: Function},
    removeResponse: {type: Function, required: true},
    fileList: {type: Array, required: true},
    loading: {type: Boolean, required: false},
    waterText: {type: String, default: ''},
    size: {type: Number, default: 5000},  // 限制图片大小 单位kb
    isToken: {type: Boolean, default: false},
    props: {type: Object},
    compress: {type: Boolean, default: true}, // 压缩图片
  },

  render() {
    return (
        <div>
          {/*渲染上传组件*/}
          <div>{this.renderUpload()}</div>
          {/*渲染弹出预览*/}
          <div>{this.renderDialogImg()}</div>
        </div>
    )
  },

  data() {
    return {
      dialogImageUrl: '',
      isShowDialogImg: false,
    }
  },
  computed: {
    isShowUpload: function () {
      return this.fileList?.length < this.imgLength
    }
  },
  methods: {
    // 渲染上传组件
    renderUpload() {
      const scopedSlots = {
        file: (props) => {
          return (<div class={'ch-upload-img-wrapper'}>
            {/*  图片*/}
            {this.renderImage({src: props.file.url})}
            {/*<img class={'el-upload-list__item-thumbnail'} src={props.file.url} alt="" />*/}
            <span class={'el-upload-list__item-actions'}>
              <span class={'el-upload-list__item-preview'}
                    onClick={() => this.handleShowDialogImg(props.file) }>
                <i class={'el-icon-zoom-in'}></i>
              </span>
              <span class={'el-upload-list__item-delete'}
                    onClick={() => this.handleRemove(props.file)}>
                <i class={'el-icon-delete'}></i>
              </span>
            </span>
          </div>)
        },
      }
      return (<el-upload
          class={'upload-img-wrapper'}
          ref={'uploadImg'}
          action={'#'}
          {...(this.props)}
          props={{
            onChange: this.changeFile,
            fileList: this.fileList,
            ...(this.props),
            ...this.$attrs
          }}
          on={{...this.$listeners}}
          list-type={'picture-card'}
          auto-upload={false}
          class={this.isShowUpload ? 'showUploadImg' : 'unShowUploadImg'}
          {...{scopedSlots}}
      >
        <i class={"el-icon-plus"} />
        {this.$slots.tip && <div slot={'tip'} class={'el-upload__tip upload-img-tip'}>{this.$slots.tip}</div>}
      </el-upload>)
    },
    // 渲染图片
    renderImage({src}) {
      const scopedSlots = {
        placeholder: () => {
          return (
              <el-image
                  src={loaded}
                  style={{width: '100%', height: '100%'}} />
          )
        },
        error: () => {
          return (
              <el-image
                  src={loaded}
                  style={{width: '100%', height: '100%'}} />
          )
        }
      }
      return (
          <el-image
              src={src}
              {...{scopedSlots}}
              style={{width: '100%', height: '100%'}} />
      )
    },

    // 执行hover 图片 + 号 预览图片
    handleShowDialogImg(file){
      this.dialogImageUrl = file.url;
      this.isShowDialogImg = true;
    },

    // 上传执行函数
    async changeFile(file, fileList) {
      // let fileType = file.name.replace(/.+\./, "")
      const isJPG = /\.(jpeg|jpg|png|svg|pdf)$/i.test(file.name)
      if(!isJPG){
        this.$message.error('图片只能是 JPEG/JPG/PNG 格式!');
        this.handleRemove(file, fileList)
      }else {
        // 水印
        if(this.waterText){
          this.addWatermark(file,fileList,this.waterText)
          return
        }
        if(file.raw.type === 'image/svg+xml' || file.raw.type === 'application/pdf' || this.compress === false){
          this.$emit('update:loading', true)
          let formData = new FormData()
          formData.append("file", file.raw)
          req.upload(this.action, formData).then((res) => {
            const params = res
            params.data.uid = file.uid
            console.log(fileList,'fileList')
            this.$emit('update:fileList', fileList)
            this.uploadResponse?.(params,file,fileList)
          }).catch(() => {
            this.handleRemove(file, fileList)}
          ).finally(()=>{this.$emit('update:loading', false)})
          return
        }

        let fd = new FileReader();
        let UploadFile = file.raw || file
        fd.readAsDataURL(UploadFile);
        fd.onload = async () => {
          const canvas = document.createElement("canvas");
          const img = new Image();
          img.src = fd.result;
          img.onload = async () => {
            const ctx = canvas.getContext("2d");
            await this.resizeMe(img, canvas)
            // 在canvas绘制前填充白色背景
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // 绘制图片大小和先前图片一致
            // 输出压缩后的base64
            file.url = canvas.toDataURL('image/jpeg')
            // 随机文件名
            const fileName = `${Date.now()}${Math.floor(Math.random() * 10000)}`
            const newFile = await this.base64toFilelet(file.url, fileName)
            const newFileSizeFlag = (newFile.size / 1024 / 1024)*1000 > this.size
            if(newFileSizeFlag){
              this.$message.error(`压缩后的图片大小不能超过 ${newFile.size / 1024 / 1024}m!`);
              this.handleRemove(file, fileList)
              return
            }
            let formData = new FormData()
            formData.append("file", newFile)
            this.$emit('update:loading', true)
            req.upload(this.action, formData).then((res) => {
              file.url = res.data.url
              this.$emit('update:fileList', fileList)
              this.uploadResponse?.(res,file,fileList)
            }).catch(() => {this.handleRemove(file, fileList)}).finally(()=>{this.$emit('update:loading', false)})
          }
        }
      }

    },
    // 执行hover 图片 删除
    handleRemove(file, fileList) {
      let newFileList = this.fileList?.filter((item) => {
        return item.uid !== file.uid
      })
      this.$emit('update:fileList', newFileList)
      this.removeResponse?.(file, fileList)
    },
    // 弹出 Dialog
    renderDialogImg() {
      let that = this
      // 关键代码 .sync
      const listeners = {
        'update:visible': value => {
          that.isShowDialogImg = value
        }
      }
      // 弹出预览图片
      return (<el-dialog
          width={"30%"}
          class={'img-dialog-wrapper'}
          visible={ that.isShowDialogImg } {...{ on: listeners }}
          appendToBody={ true }>
        <img src={ that.dialogImageUrl } alt={'未加载'} />
      </el-dialog>)
    },
    //canvas对象转为blob对象
    canvas2Blob(canvas) {
      return new Promise((resolve) => {
        canvas.toBlob(blob => resolve(blob))
      })
    },
    // 添加水印
    addWatermark(file,fileList,text){
      let fd = new FileReader();
      let UploadFile = file.raw || file
      fd.readAsDataURL(UploadFile);
      fd.onload = async () => {
        const canvas = document.createElement("canvas");
        const img = new Image();
        img.src = fd.result;
        img.onload = async () => {
          const ctx = canvas.getContext("2d");
          await this.resizeMe(img, canvas)
          // 在canvas绘制前填充白色背景
          ctx.fillStyle = "#fff";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // 绘制图片大小和先前图片一致
          ctx.save();
          // 设置初始字体大小
          let fontSize = 24;

          // 创建一个临时的 span 元素来计算文字宽度
          const span = document.createElement('span');
          span.style.fontSize = `${fontSize}px`;
          span.style.visibility = 'hidden';
          span.style.position = 'absolute';
          span.style.top = '-9999px';
          span.style.left = '-9999px';
          span.innerText = text;
          document.body.appendChild(span);
          // 计算文字宽度和高度
          const textWidth = span.offsetWidth;
          const textHeight = span.offsetHeight;

          // 移除临时的 span 元素
          document.body.removeChild(span);

          // 根据容器的大小调整字体大小
          const widthRatio = canvas.width / textWidth /10;
          const heightRatio = canvas.height / textHeight /10;
          const scaleRatio = Math.min(widthRatio, heightRatio);

          fontSize *= scaleRatio;

          ctx.fillStyle = "#dedede"; // 水印颜色，透明度
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = `bold ${fontSize}px Arial`; // 大小与字体按需求调整
          ctx.fillText(
              text,
              canvas.width / 2,
              canvas.height / 2
          ); // 添加的文字
          let data = canvas.toDataURL('image/jpeg') // 输出压缩后的base64
          file.url= data
          const fileName = `${Date.now()}${Math.floor(Math.random() * 10000)}`
          const newFile = await this.base64toFilelet(data, fileName)
          let newFileSizeFlag = (newFile.size / 1024 / 1024)*1000 > this.size
          if(newFileSizeFlag){
            this.$message.error(`图片大小不能超过 ${this.size}kb!`);
            this.handleRemove(file, fileList)
            return
          }
          let formData = new FormData()
          formData.append("file", newFile)
          this.$emit('update:loading', true)
          req.upload(this.action, formData).then((res) => {
            this.$emit('update:fileList', fileList)
            this.uploadResponse?.(res,file,fileList)
          }).catch(() => {
            this.handleRemove(file, fileList)
          }).finally(()=>{this.$emit('update:loading', false)})
        };
      }
    },
    base64toFilelet(dataurl, filename = 'file') {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      // suffix是该文件的后缀
      let suffix = mime.split('/')[1]
      // atob 对经过 base-64 编码的字符串进行解码
      let bstr = atob(arr[1])
      // n 是解码后的长度
      let n = bstr.length
      // Uint8Array 数组类型表示一个 8 位无符号整型数组 初始值都是 数子0
      let u8arr = new Uint8Array(n)
      // charCodeAt() 方法可返回指定位置的字符的 Unicode 编码。这个返回值是 0 - 65535 之间的整数
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // new File返回File对象 第一个参数是 ArraryBuffer 或 Bolb 或Arrary 第二个参数是文件名
      // 第三个参数是 要放到文件中的内容的 MIME 类型
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    // canvas 压缩
    resizeMe(img, canvas) {
      let targetWidth = img.width
      let targetHeight = img.height
      let originWidth = img.width
      let originHeight = img.height

      let maxWidth = 480
      let maxHeight = 640
      // 图片尺寸超过的限制
      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > maxWidth / maxHeight) {
          // 更宽，按照宽度限定尺寸
          targetWidth = maxWidth
          targetHeight = Math.round(maxWidth * (originHeight / originWidth))
        } else {
          targetHeight = maxHeight
          targetWidth = Math.round(maxHeight * (originWidth / originHeight))
        }
      }
      canvas.width = targetWidth
      canvas.height = targetHeight
    },

  }
}